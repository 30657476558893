<template>
  <div v-if="!loading">
    <Card :title="title" :disabled="!editMode">
      <template #close>
        <a @click.prevent="$router.go(-1)">
          <v-btn icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </a>
      </template>
      <template #actions>
        <v-btn
          v-permission="{
            permissions: ['agent-management'],
          }"
          @click="editMode = !editMode"
        >
          <span>{{ editMode ? "Görüntüle" : "Düzenle" }}</span>
        </v-btn>
      </template>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-card flat class="pa-4">
          <v-container>
            <h2 class="mb-4">Bilgiler</h2>
            <v-row>
              <v-col cols="10" md="5">
                <v-text-field
                  v-model="currentAgent.name"
                  :counter="50"
                  label="Agent Adı"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="2"
                class="pa-0 ma-0 d-flex align-center justify-start pt-4"
                md="1"
              >
                <v-btn
                  :to="{ name: 'user-edit', params: { id: currentAgent.id } }"
                  color="primary"
                  outlined
                  x-small
                >
                  Kullanıcıya Git
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <SMPhoneSelectbox
                  :phone="currentAgent.phone_number"
                  @update:phone="(phone) => (currentAgent.phone_number = phone)"
                ></SMPhoneSelectbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="currentAgent.agent_information.lead_count"
                  label="Lead Sayısı"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="d-flex justify-content-end">
                  <v-btn class="mr-3" color="error" @click="reset">
                    İptal
                  </v-btn>
                  <v-btn
                    class="mr-3"
                    :disabled="!isFormValid"
                    color="primary"
                    @click="save"
                  >
                    Kaydet
                  </v-btn>
                  <v-btn
                    color="primary"
                    :disabled="!isFormValid"
                    @click="saveAndClose"
                  >
                    Kaydet ve Kapat
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-form>
    </Card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Card from "@/view/content/components/Card";
import SMPhoneSelectbox from "@/view/content/siliconmade/SM-Phone-Selectbox.vue";
import {
  CREATE_AGENT,
  UPDATE_AGENT,
  GET_AGENT,
} from "@/core/services/store/agents.module";

export default {
  name: "AgentManagementEdit",
  components: { Card, SMPhoneSelectbox },
  data() {
    return {
      editMode: false,
      currentAgent: {},
      loading: !!this.$route.params.id,
      isFormValid: true,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.getAgent(this.$route.params.id)
        .then(() => {
          this.currentAgent = Object.assign({}, this.agent);
          this.currentAgent.phone_number =
            this.currentAgent.agent_information.phone_number;
        })
        .finally(() => {
          this.loading = false;
        });
    }

    this.$route.query.editable
      ? (this.editMode = true)
      : (this.editMode = false);
  },
  methods: {
    ...mapActions([GET_AGENT, UPDATE_AGENT, CREATE_AGENT]),
    async save(replaceRoute = true) {
      const valid = this.$refs.form.validate();
      if (!valid) {
        this.isFormValid = false;
        return;
      }
      if (this.$route.params.id) {
        try {
          const agent = JSON.parse(JSON.stringify(this.currentAgent));
          const data = {
            ...agent,
            id: this.$route.params.id,
          };
          await this.updateAgent(data);
          this.$toast.success("Agent güncellendi", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.stopExecution = true;
          this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      } else {
        try {
          const agent = await this.createAgent(this.currentAgent);
          if (replaceRoute) {
            this.$router.push({
              name: "agent-management-edit",
              params: {
                id: agent.id,
                query: { editable: true },
              },
            });
          }
          this.$toast.success("Agent oluşturuldu", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.stopExecution = true;
          this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      }
    },
    async saveAndClose() {
      await this.save(false);
      if (this.isFormValid && !this.stopExecution)
        this.$router.push({ name: "agent-management" });
      this.stopExecution = false;
    },
    reset() {
      if (this.$route.params.id) {
        this.currentAgent = Object.assign({}, this.agent);
      } else {
        this.currentAgent = {};
      }
      this.$router.push({ name: "agent-management" });
    },
    openIconDialog() {
      window.open(
        "https://pictogrammers.github.io/@mdi/font/1.1.34/",
        "_blank"
      );
    },
  },
  computed: {
    ...mapGetters(["agent"]),
    title() {
      if (this.$route.params.id) {
        if (this.editMode) {
          return "Agent Düzenle";
        }
        return "Agent Detay";
      }
      return "Agent Yaratma";
    },
  },
};
</script>

<style></style>
